var logo_line = function () {
    var last_letter_postition = $('#logo #last_letter').position();
    var logo_line_position = last_letter_postition.left + 18;
    $('#logo_line').css('left', last_letter_postition.left + 18);
    // $('#logo_line').css('min-height', 64);

    var head_content_position = $('#head_content').position();
    var head_content_width = $('#head_content').outerWidth();
    var head_content_right = head_content_position.left + head_content_width;


    if ($(window).width() > 991) {
        $('#head_content h1').css('margin-right', head_content_right - last_letter_postition.left - 18 + 78);
    } else {
        $('#head_content h1').css('margin-right', head_content_right - last_letter_postition.left - 18 + 20);
    }
    
    if ($(window).width() > 991) {
        $('#head_content h1').css('margin-left', $('.navbar-nav').position().left);
    } else {
        $('#head_content h1').css('margin-left', $('#ueberuns .first').position().left + 30);
    }
    

    $(window).scroll(function () {
        var head_bottom = $('#head').offset().top + $('#head').outerHeight();
        var logo_line_bottom = $('#logo_line').offset().top + $('#logo_line').outerHeight();
        var difference = head_bottom - logo_line_bottom;

        var logo_line_height = $('#logo_line').outerHeight();
        var logo_line_new_height = logo_line_height + difference - 22;
        $('#logo_line').css('transition', 'none');
        $('#logo_line').css('height', logo_line_new_height);

        if (logo_line_new_height < 64) {
            $('.navbar').addClass('small');
        } else {
            $('.navbar').removeClass('small');
        }
    })

}

var scrollDirection = function () {
    var lastScrollTop = 0;
    var scrollDirection;
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
            scrollDirection = 'down';
        } else {
            scrollDirection = 'up';
        }
        lastScrollTop = st;
        return scrollDirection;
    });
}

var sawblade = function () {

    $(window).scroll(function () {
        var offset = $('#leistungen').offset().top - ($(window).height() / 2);
        var scroll = $(window).scrollTop();

        if (offset <= scroll) {
            $('.sawblade').addClass('show');
        } else {
            $('.sawblade').removeClass('show');
        }
    })
}

$(document).ready(function () {

    $('.leistungen').wrap('<div id=\'leistungen\'></div>');

    logo_line();

    if ($('#leistungen').length > 0) {
        sawblade();
    }


    $('#head h1').css('opacity', 1);
    $('#logo_line').css('height', 456);

    $('body').scrollspy({
        target: ".navbar",
        offset: 45
    });

    $("body.index #navbar a.nav-link").on('click', function (event) {

        if (this.hash !== "") {

            event.preventDefault();
            var hash = this.hash;

            // if(hash == '#ueberuns') {
            // var offset = $(hash).offset().top - 110;
            // } else {
            var offset = $(hash).offset().top - 44;
            // }

            $('html, body').animate({
                scrollTop: offset
            }, 800);

        }

    });

});

$(window).resize(function () {
    logo_line();
    sawblade();
});